import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { AuthService } from './auth/auth.service';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { PermissionsGuard } from './core/permissions.guard';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';
import { environment } from '@env/environment';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    CommonModule,
    CoreModule,
    ToastrModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.ga_trackingid),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    AuthService,
    PermissionsGuard,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
