import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { JwtHelper } from '@app/core/jwt.helper';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private jwtHelper = new JwtHelper();
  private readonly orderedScreens = [
    'LS_LIVE_FEED',
    'LS_PLAYBACK',
    'LS_HEATMAP',
    'LS_AREA_ANALYTICS',
    'LS_MOVMENT_ANALYTICS',
    'LS_DIST_ANALYTICS',
    'LS_DOWNLOADS',
    'LS_MACHINE_ANALYTICS',
    'LS_OCCUPANCY_ANALYTICS',
    'LS_LIVE_ZONE_OCCUPANCY',
    'LS_ASSET_HISTORY',
    'LS_ASSET_CLASSIFICATION'
  ];

  private readonly screenPermissionsNamesMap = {
    LS_LIVE_FEED: '/home/live',
    LS_PLAYBACK: '/home/analytics/playback',
    LS_HEATMAP: '/home/analytics/heatmap',
    LS_AREA_ANALYTICS: '/home/analytics/area',
    LS_MOVMENT_ANALYTICS: '/home/analytics/movement',
    LS_DIST_ANALYTICS: '/home/analytics/distance',
    LS_DOWNLOADS: '/home/analytics/downloads',
    LS_MACHINE_ANALYTICS: '/home/analytics/machine',
    LS_LIVE_ZONE_OCCUPANCY: '/home/analytics/zone-occupancy',
    LS_ASSET_HISTORY: '/home/asset/history',
    LS_ASSET_CLASSIFICATION: '/home/asset/classification'
  };

  private readonly LOGIN_SCREEN = '/auth/login';

  constructor(private authService: AuthService) {}

  private getModules() {
    const token = this.authService.getToken();
    const decodedToken = this.jwtHelper.decodeToken(token);

    return decodedToken?.access?.modules || [];
  }

  public getPermittedScreens() {
    const availableScreensMap = this.getModules().reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    );

    return this.orderedScreens
      .filter(screen => availableScreensMap[screen])
      .map(module => this.screenPermissionsNamesMap[module])
      .filter(Boolean);
  }

  public isCalendarPermitted() {
    return this.getModules().includes('CAL_NEW_TAB');
  }

  public isAssetManager() {
    return this.getModules().includes('LS_ASSET_MANAGER');
  }

  public getPermittedScreensMap(): Map<string, boolean> {
    const screensMap = new Map();
    const permittedScreens = this.getPermittedScreens();
    permittedScreens.forEach(screen => {
      screensMap.set(screen, true);
    });
    return screensMap;
  }

  public isLiveSectionPermitted() {
    const screensMap = this.getPermittedScreensMap();
    return screensMap.has(this.screenPermissionsNamesMap.LS_LIVE_FEED);
  }

  public isOccupancyAnalyticsPermitted() {
    return this.getModules().includes('LS_OCCUPANCY_ANALYTICS');
  }

  public isAnalyticsSectionPermitted() {
    return !!this.getLinkForDefaultAnalyticsRedirect().length;
  }

  public isAssetSectionPermitted() {
    const screensMap = this.getPermittedScreensMap();
    return (
      screensMap.has(this.screenPermissionsNamesMap.LS_ASSET_HISTORY) ||
      screensMap.has(this.screenPermissionsNamesMap.LS_ASSET_CLASSIFICATION)
    );
  }

  public getLinkForDefaultAnalyticsRedirect() {
    const screensMap = this.getPermittedScreensMap();
    const childScreens = Object.values(
      this.screenPermissionsNamesMap
    ).filter(link => link.startsWith('/home/analytics'));
    const availableChildScreens = childScreens.filter(screen =>
      screensMap.has(screen)
    );
    return availableChildScreens.slice(0, 1);
  }
}
