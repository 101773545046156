import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class QueryCounterService {
  public counter = 0;
  public stateChange: Subject<boolean> = new Subject();

  public decrease() {
    if (this.counter > 0) this.counter--;
    if (this.counter === 0) setTimeout(() => this.stateChange.next(false));
  }
  public increase() {
    this.counter++;
    setTimeout(_ => this.stateChange.next(true));
  }
  public isActive() {
    return this.counter > 0;
  }
}
