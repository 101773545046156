import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { IsDevGuard } from './debug/is-dev.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'home',
    loadChildren: () => import('app/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'debug',
    canActivate: [IsDevGuard],
    loadChildren: () =>
      import('app/debug/debug.module').then(m => m.DebugModule)
  },
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/auth/login'
  }
];

if (environment.dev) {
  routes.unshift();
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
