import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { TreeModule } from 'primeng/tree';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';

import { DurationPipe } from './pipes/duration.pipe';
import { SafePipe } from './pipes/safe.pipe';

import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RefreshBtnComponent } from './refresh-btn/refresh-btn.component';
import { SelectCheckAllComponent } from './select-check-all/select-check-all.component';
import { TimePickerComponent } from './timePicker/timePicker.component';
import { Playback3DComponent } from '@app/shared/playback3D/playback3D.component';
import { AssetsTreeComponent } from './assets-tree/assets-tree.component';

import { SizeColorDirective } from './sizeColor.directive';
import { CoursaTimeDirective } from './timeMask.directive';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SidebarModule } from 'primeng/sidebar';
import { DatepickerComponent } from './rangeDatepicker/datepicker.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';

import { KeyFilterModule } from 'primeng/keyfilter';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { OverlayModule } from '@angular/cdk/overlay';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MatDateRangePickerComponent } from './matDateRangePicker/mat-date-range-picker.component';
import { SliderModule } from 'primeng/slider';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TimerangePickerV2Component } from '@app/movement/timerange-picker-v2/timerange-picker.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatSliderModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatListModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatTreeModule,

    TreeModule,
    CalendarModule,
    DropdownModule,
    SelectButtonModule,
    TooltipModule,
    AccordionModule,
    TabViewModule,
    TableModule,
    OverlayPanelModule,
    BreadcrumbModule,
    SidebarModule,
    // Daterangepicker,
    MultiSelectModule,
    MatProgressSpinnerModule,

    ColorPickerModule,
    DialogModule,
    FileUploadModule,
    InputNumberModule,
    KeyFilterModule,
    NgxSliderModule,

    OverlayModule,
    CheckboxModule,
    ConfirmDialogModule,
    // ConfirmationService
    InfiniteScrollDirective,
    SliderModule,
    AutoCompleteModule
  ],
  declarations: [
    DurationPipe,
    SafePipe,

    SizeColorDirective,
    CoursaTimeDirective,

    ProgressBarComponent,
    RefreshBtnComponent,
    SelectCheckAllComponent,
    TimePickerComponent,
    Playback3DComponent,
    AssetsTreeComponent,
    DatepickerComponent,
    MatDateRangePickerComponent,
    TimerangePickerV2Component
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatSliderModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatListModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatTreeModule,

    TreeModule,
    CalendarModule,
    DropdownModule,
    SelectButtonModule,
    TooltipModule,
    AccordionModule,
    TabViewModule,

    DurationPipe,
    SafePipe,

    SizeColorDirective,
    CoursaTimeDirective,

    ProgressBarComponent,
    RefreshBtnComponent,
    SelectCheckAllComponent,
    TimePickerComponent,
    Playback3DComponent,
    AssetsTreeComponent,
    TableModule,
    OverlayPanelModule,
    BreadcrumbModule,
    SidebarModule,
    MatCardModule,
    DatepickerComponent,
    MultiSelectModule,
    MatProgressSpinnerModule,

    ColorPickerModule,
    DialogModule,
    FileUploadModule,
    InputNumberModule,
    KeyFilterModule,
    NgxSliderModule,
    OverlayModule,
    CheckboxModule,
    ConfirmDialogModule,
    InfiniteScrollDirective,
    MatDateRangePickerComponent,
    SliderModule,
    AutoCompleteModule,
    TimerangePickerV2Component
    // ConfirmationService
  ],
  providers: [ConfirmationService]
})
export class SharedModule {}
