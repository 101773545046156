export const environment = {
  production: true,
  test: false,
  dev: false,
  appUrl: 'https://api.venuepositioning.com/v1/coursa/',
  elasticUrl:
    'https://vizj3zzdt2.execute-api.us-east-1.amazonaws.com/dev/v1/coursa/app/venue/dashboard/coursa-search/coursa-search-ls/invn-coursa-v/',
  movementElasticUrl:
    'https://vizj3zzdt2.execute-api.us-east-1.amazonaws.com/dev/v1/anar/',
  movementAssetsElasticUrl:
    'https://vizj3zzdt2.execute-api.us-east-1.amazonaws.com/dev/v1/asar/',
  distanceElasticUrl:
    'https://vizj3zzdt2.execute-api.us-east-1.amazonaws.com/dev/v1/ana2a/',
  distanceAssetsElasticUrl:
    'https://vizj3zzdt2.execute-api.us-east-1.amazonaws.com/dev/v1/asa2a/',
  vdrElasticUrl:
    'https://vizj3zzdt2.execute-api.us-east-1.amazonaws.com/dev/v1/vdrprod/',
  wsConfig: {
    configUrl: 'https://api.venuepositioning.com/v1/coursa/stream/auth/wss'
  },
  ga_trackingid: 'G-Y662WNLH41'
};
