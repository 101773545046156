import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { QueryCounterService } from './core/queryCounter.service';

@Component({
  selector: 'app-root',
  template: `
    <mat-progress-bar
      *ngIf="queryCounter.stateChange | async"
      class="query-progress"
      mode="query"
    >
    </mat-progress-bar>
    <router-outlet></router-outlet>
  `,
  styles: []
})
export class AppComponent {
  constructor(
    private router: Router,
    public queryCounter: QueryCounterService
  ) {}
}
