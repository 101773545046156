import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@env/environment';

@Injectable()
export class IsDevGuard {
  constructor(private router: Router) {}

  canActivate() {
    if (environment.dev) {
      return true;
    } else {
      this.router.navigate(['/home']);
    }
  }
}
