import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FiltersService } from '@app/core/filters.service';
import { JwtHelper } from '@app/core/jwt.helper';

export interface AuthUser {
  access: any;
  apiKey: string;
  applicationName: string;
  customers: any[];
  email: string;
  exp: number;
  firstName: string;
  iat: number;
  invnUser: boolean;
  lastName: string;
  policy: string;
  userId: string;
}

@Injectable()
export class AuthService {
  private jwtHelper = new JwtHelper();
  constructor(private router: Router, private filtersService: FiltersService) {}

  public setToken(token) {
    localStorage.setItem('token', token);
  }

  public getToken() {
    return localStorage.getItem('token');
  }

  public deleteToken() {
    this.filtersService.resetVenueList();
    return localStorage.removeItem('token');
  }

  public isAuth() {
    const decodedToken = this.jwtHelper.decodeToken(this.getToken());
    const nowTime = new Date().getTime() / 1000;
    return !!(decodedToken && decodedToken.exp > nowTime);
  }

  public checkUserIsInvnUser() {
    if (this.getToken()) {
      const decodedToken = this.jwtHelper.decodeToken(this.getToken());
      return decodedToken.invnUser;
    }
    return false;
  }

  public getAuthUser(): AuthUser {
    if (this.getToken()) return this.jwtHelper.decodeToken(this.getToken());
    else return null;
  }

  public logout(): void {
    this.deleteToken();
    this.router.navigate(['/auth/login']);
  }
}
