import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface VenueListItem {
  lat: number;
  lng: number;
  radius: number;
  updatedAt: Date;
  venueDesc: string;
  venueId: number;
  venueName: string;
  venueUuid: string;
}

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  public minDate: Moment;
  public maxDate: Moment;
  public timezone: string;

  set selectedVenue(venue: VenueListItem) {
    this._selectedVenue = venue;
  }
  get selectedVenue(): VenueListItem {
    return this._selectedVenue;
  }
  private _selectedVenue: VenueListItem;

  get selectedVenueId(): number {
    return this._selectedVenue.venueId;
  }
  set selectedVenueId(id: number) {
    const venue = this.venueList.find(v => v.venueId === id);
    if (venue) this._selectedVenue = venue;
    else console.warn(`Venue with ID ${id} not found!`);
  }

  private venueList: VenueListItem[];

  constructor(private http: HttpClient) {}

  public resetVenueList() {
    this.venueList = undefined;
  }

  public getVenues() {
    if (!this.venueList) {
      return this.http.get('app/app/venue/dashboard/partner/maps').pipe(
        map((res: any) => {
          this.venueList = res.data
            .map(v => ({
              lat: Number(v.lat),
              lng: Number(v.lng),
              radius: Number(v.radius),
              updatedAt: new Date(v.updatedAt),
              venueDesc: v.venueDesc,
              venueId: v.venueId,
              venueName: v.venueName,
              venueUuid: v.venueUuid
            }))
            .sort((a, b) => (a.venueDesc < b.venueDesc ? -1 : 1));
          return this.venueList;
        })
      );
    } else return of(this.venueList);
  }
}
