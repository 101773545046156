import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class EndpointInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = request.url;

    if (url.startsWith('app/')) {
      url = url.replace(/app\//, environment.appUrl);
      request = request.clone({ url });
    } else if (url.startsWith('elastic/')) {
      url = url.replace(/elastic\//, environment.elasticUrl);
      request = request.clone({ url });
    } else if (url.startsWith('movement/')) {
      url = url.replace(/movement\//, environment.movementElasticUrl);
      request = request.clone({ url });
    } else if (url.startsWith('assets-movement')) {
      url = url.replace(
        /assets-movement\//,
        environment.movementAssetsElasticUrl
      );
      request = request.clone({ url });
    } else if (url.startsWith('distance/')) {
      url = url.replace(/distance\//, environment.distanceElasticUrl);
      request = request.clone({ url });
    } else if (url.startsWith('assets-distance/')) {
      url = url.replace(
        /assets-distance\//,
        environment.distanceAssetsElasticUrl
      );
      request = request.clone({ url });
    } else if (url.startsWith('/venue/dashboard')) {
      url = environment.appUrl.replace('/v1/coursa/', url);
      request = request.clone({ url });
    }

    return next.handle(request);
  }
}
