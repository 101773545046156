import { X_API_KEY } from './xApiKey';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

export const TokenInterceptorSkipHeader = 'X-TOKEN-INTERCEPTOR-SKIP';
export const TokeSignInInterceptorSkipHeader =
  'X-SignIn-TOKEN-INTERCEPTOR-SKIP';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const skipIntereptor = request.headers.has(TokenInterceptorSkipHeader);

    const token = this.authService.getToken();
    if (!skipIntereptor) {
      const skipSignInIntereptor = request.headers.has(
        TokeSignInInterceptorSkipHeader
      );

      if (!skipSignInIntereptor && token) {
        request = request.clone({
          headers: request.headers.set(
            'Authorization',
            'Bearer ' + this.authService.getToken()
          )
        });
      }

      request = request.clone({
        headers: request.headers.set('x-api-key', X_API_KEY)
      });

      if (skipSignInIntereptor) {
        request = request.clone({
          headers: request.headers.delete(TokeSignInInterceptorSkipHeader, '')
        });
      }
    } else {
      request = request.clone({
        headers: request.headers.delete(TokenInterceptorSkipHeader, '')
      });
    }
    return next.handle(request);
  }
}
