import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { QueryCounterService } from './queryCounter.service';
import { throwError } from 'rxjs';

@Injectable()
export class QueryCounterInterceptor implements HttpInterceptor {
  constructor(private queryCounterService: QueryCounterService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let retryRequest;
    if (request && request.body && request.body.retryRequest) {
      retryRequest = true;
      delete request.body.retryRequest;
    } else {
      this.queryCounterService.increase();
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event && event.type) {
          if (!retryRequest) {
            this.queryCounterService.decrease();
          }
        }
        return event;
      }),
      catchError((err: any) => {
        if (!retryRequest) {
          this.queryCounterService.decrease();
        }
        return throwError(err);
      })
    );
  }
}
