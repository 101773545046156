import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { PermissionsService } from '@app/auth/services/permissions.service';

@Injectable()
export class PermissionsGuard {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const permittedScreens = this.permissionsService.getPermittedScreens();
    const isPermitted = permittedScreens.includes(state.url);
    if (!permittedScreens.length) {
      this.router.navigate(['/auth/login']);
    } else if (!isPermitted) {
      if (state.url.includes('/home/asset')) {
        const page = this.getAssetActivePage();
        if (page && page.length > 0) {
          this.router.navigate([page]);
          return;
        }
      }

      this.router.navigate([permittedScreens[0]]);
    }
    return isPermitted;
  }

  getAssetActivePage(): string {
    const linksList = [
      {
        name: 'History',
        link: 'history'
      },
      {
        name: 'Classification',
        link: 'classification'
      }
    ];
    const permittedScreensMap = this.permissionsService.getPermittedScreensMap();
    const links = linksList.filter(({ link }) => {
      return permittedScreensMap.has(`/home/asset/${link}`);
    });

    return links && links.length > 0 ? `/home/asset/${links[0].link}` : null;
  }
}
